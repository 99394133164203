import { useSuspenseQuery } from '@tanstack/react-query';

import { get } from '@api/httpClient';

import {
  type OutputIDAnnotationTableSchema,
  type OutputIDExperimentSchema,
} from '@/zod_schemas';

const QUERY_KEYS_OUTPUTID = {
  OUTPUTID: (id: string) => ['outputId', { id }],
};

export const useGetOutputIDExperiment = (id?: string) =>
  useSuspenseQuery<OutputIDExperimentSchema>({
    queryKey: QUERY_KEYS_OUTPUTID.OUTPUTID(id || ''),
    queryFn: async () => {
      if (!id) return {};

      const resp = await get(`/output/${id}/experiment/`);
      return resp.data;
    },
  });

const QUERY_KEYS_ANNOTATION_OUTPUT = {
  ANNOTATION_OUTPUT: (id: string) => ['outputId', { id }],
};

// const test = [
//   {
//     id: '07ed30bc-6f50-4406-ad70-3ea587f32a67',
//     uploaded_variation: '1_69270_A/G',
//     location: '1:69270',
//     allele: 'G',
//     qual: 1206.06,
//     info: 'AC=2;AF=1.00;AN=2;DP=42;ExcessHet=0.0000;
// FS=0.000;MLEAC=2;MLEAF=1.00;MQ=31.69;QD=28.72;SOR=7.523',
//     s103: '1/1:0,42:42:99:1220,126,0',
//     '1000Gp3_AC': '14',
//     '1000Gp3_AF': '0.1',
//     '1000Gp3_AFR_AC': null,
//     '1000Gp3_AFR_AF': null,
//     APPRIS: null,
//     Amino_acids: null,
//     BAM_EDIT: null,
//     CADD_phred: null,
//     CADD_phred_hg19: null,
//   },
// ];

// // Utility function to generate random strings
// const getRandomString = (length: number) =>
//   Math.random()
//     .toString(36)
//     .substring(2, 2 + length);

// // Utility function to generate a random number within a range
// const getRandomNumber = (min: number, max: number) =>
//   Math.random() * (max - min) + min;

// // Function to create a random object
// const createRandomObject = (template: (typeof test)[0]) => ({
//   id: getRandomString(36),
//   uploaded_variation: `${getRandomNumber(1, 22).toFixed(0)}_${getRandomNumber(
//     10000,
//     99999,
//   ).toFixed(0)}_${getRandomString(1)}/${getRandomString(1)}`,
//   location: `${getRandomNumber(1, 22).toFixed(0)}:${getRandomNumber(
//     10000,
//     99999,
//   ).toFixed(0)}`,
//   allele: getRandomString(1).toUpperCase(),
//   qual: parseFloat(getRandomNumber(1000, 2000).toFixed(2)),
//   info: `AC=${getRandomNumber(1, 5).toFixed(0)};AF=${getRandomNumber(
//     0.1,
//     1,
//   ).toFixed(2)};AN=2;DP=${getRandomNumber(20, 100).toFixed(
//     0,
//   )};ExcessHet=0.0000;FS=0.000;MLEAC=${getRandomNumber(1, 5).toFixed(
//     0,
//   )};MLEAF=${getRandomNumber(0.1, 1).toFixed(2)};MQ=${getRandomNumber(
//     30,
//     50,
//   ).toFixed(2)};QD=${getRandomNumber(20, 50).toFixed(2)};SOR=${getRandomNumber(
//     5,
//     10,
//   ).toFixed(3)}`,
//   s103: `1/1:0,${getRandomNumber(10, 50).toFixed(0)}:${getRandomNumber(
//     10,
//     50,
//   ).toFixed(0)}:99:${getRandomNumber(1000, 2000).toFixed(0)},${getRandomNumber(
//     100,
//     500,
//   ).toFixed(0)},0`,
//   '1000Gp3_AC': getRandomNumber(10, 100).toFixed(0),
//   '1000Gp3_AF': getRandomNumber(0.01, 0.5).toFixed(2),
//   '1000Gp3_AFR_AC': 'xsssasd',
//   '1000Gp3_AFR_AF': 'xsssasd',
//   APPRIS: 'xsssasd',
//   Amino_acids: 'xsssasd',
//   BAM_EDIT: 'xsssasd',
//   CADD_phred: null,
//   CADD_phred_hg19: null,
// });

export const useGetOutputAnnotationTable = (id?: string) =>
  useSuspenseQuery<OutputIDAnnotationTableSchema>({
    queryKey: QUERY_KEYS_ANNOTATION_OUTPUT.ANNOTATION_OUTPUT(id || ''),
    queryFn: async () => {
      if (!id) return {};

      const resp = await get(`/output/${id}/annotation-table/`);
      return resp.data;
    },
    // queryFn: async () => {
    //   if (!id) return { headers: [], rows: [] };

    //   // Generate headers from keys of the template object
    //   const headers = Object.keys(test[0]);

    //   // Generate an array of 5000 objects
    //   const rows = Array.from({ length: 15000 }, () =>
    //     createRandomObject(test[0]),
    //   );

    //   return { headers, rows };
    // },
  });
