import { DataGrid } from '@mui/x-data-grid';
import { useState, useEffect } from 'react';
import { MainContainer } from '../MainDashboard/StyledComponents';
import { useGetOutputAnnotationTable } from '@/api/result/result';

type Column = { field: string; headerName?: string; flex?: number };
type Row = { id: string; [key: string]: string | number | null };

const Result = () => {
  const outputID = '1d4581ef-58b5-4b2f-914c-7629a81c2f38'; // Hardcoded -> later will be replace with output id from experiments
  const { data: outputData } = useGetOutputAnnotationTable(outputID);

  const [columns, setColumns] = useState<Column[]>([]);
  const [rowsData, setRows] = useState<Row[]>([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (!outputData) return;

    const { headers, rows } = outputData;

    // Configuration for column handling
    const hideColumns = new Set(['id']); // Columns to hide
    const excludeColumns = new Set(['']); // Columns to exclude completely

    // Dynamically generate columns, excluding hidden and excluded columns
    const generatedColumns = headers
      .filter(
        (header) => !excludeColumns.has(header) && !hideColumns.has(header),
      )
      .map((header) => ({
        field: header,
        headerName: header.replace(/_/g, ' '), // Format header names
        flex: 1, // Adjust column width
      }));

    // Format rows to include 'id', replace null values, and exclude unwanted columns
    const formattedRows: Row[] = rows.map((row) =>
      Object.entries(row).reduce((acc, [key, value]) => {
        if (!excludeColumns.has(key)) {
          acc[key] = value === null ? '-' : value; // Replace nulls with '-'
        }
        return acc;
      }, {} as Row),
    );

    setColumns(generatedColumns);
    setRows(formattedRows);
    setLoading(false);
  }, [outputData]);

  return (
    <MainContainer sx={{ padding: 1, height: 400, width: '100%' }}>
      <DataGrid
        rows={rowsData}
        columns={columns}
        loading={loading}
        getRowId={(row) => row.id} // Use 'id' as the unique identifier for rows
        pageSizeOptions={[50, 100, 200]} // Provide selectable page size options
        disableRowSelectionOnClick // Prevent extra re-renders on row clicks
        sx={{ '& .MuiDataGrid-virtualScroller': { overflowY: 'auto' } }} // Ensure smooth scrolling
      />
    </MainContainer>
  );
};

export default Result;
