import { Button, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Suspense } from 'react';
import PatientList from './components/PatientList';
import { MainContainer } from '../MainDashboard/StyledComponents';

const PatientsHeader = () => {
  const navigate = useNavigate();

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{ paddingBottom: 2 }}
    >
      <Typography variant="h5">Patients</Typography>
      <Button
        onClick={() => navigate('create')}
        variant="contained"
        size="small"
      >
        Create Patient
      </Button>
    </Stack>
  );
};

const Patients = () => (
  <Stack direction="column" sx={{ paddingX: 1 }}>
    <MainContainer>
      <PatientsHeader />
      <Suspense fallback={<div>Loading...</div>}>
        <PatientList />
      </Suspense>
    </MainContainer>
  </Stack>
);

export default Patients;
