import {
  Avatar,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  Box,
} from '@mui/material';
import { useGetCurrentUser } from '@api/auth/auth';
import { deepOrange } from '@mui/material/colors';
import { Search, LogOut, Settings } from 'lucide-react';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useHandleLogout } from '@/pages/common/Logout/hooks';
import { SearchField } from '../StyledComponents';
import UploadsSidebarButton from './UploadSidebarButton';

const DashboardShellHeader = () => {
  const navigate = useNavigate();
  const userLogout = useHandleLogout();
  const { data } = useGetCurrentUser();

  const handleLogout = useCallback(async () => {
    await userLogout();
  }, [userLogout]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleAccountClick = () => {
    setAnchorEl(null);
    navigate('/account');
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        px: 2,
        py: 1,
        columnGap: 2,
      }}
    >
      {/* Search Field */}
      <SearchField
        id="search"
        variant="outlined"
        placeholder="Search"
        size="small"
        InputProps={{
          startAdornment: (
            <Search
              style={{
                paddingRight: '0.5rem',
              }}
            />
          ),
        }}
      />

      {/* Right Section */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          columnGap: 2,
        }}
      >
        {/* Upload Button */}
        <UploadsSidebarButton />

        {/* User Profile and Menu */}
        {data && (
          <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 1 }}>
            <Typography
              variant="subtitle2"
              sx={{
                fontWeight: 600,
                color: '#333',
              }}
            >
              Welcome, {data?.first_name}!
            </Typography>
            <Tooltip title="Account settings">
              <IconButton
                onClick={handleMenuOpen}
                size="small"
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
              >
                <Avatar
                  sx={{
                    bgcolor: deepOrange[500],
                    width: 36,
                    height: 36,
                  }}
                >
                  {data?.first_name.charAt(0)}
                </Avatar>
              </IconButton>
            </Tooltip>
          </Box>
        )}

        {/* Dropdown Menu */}
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleMenuClose}
          onClick={handleMenuClose}
          PaperProps={{
            elevation: 4,
            sx: {
              mt: 1,
              borderRadius: '8px',
              boxShadow: '0px 4px 8px rgba(0,0,0,0.1)',
              overflow: 'hidden',
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
            },
          }}
          transformOrigin={{
            horizontal: 'right',
            vertical: 'top',
          }}
          anchorOrigin={{
            horizontal: 'right',
            vertical: 'bottom',
          }}
        >
          <MenuItem onClick={handleAccountClick}>
            <Avatar /> My account
          </MenuItem>
          <Divider />
          <MenuItem onClick={() => navigate('administrator')}>
            <ListItemIcon>
              <AdminPanelSettingsOutlinedIcon fontSize="small" />
            </ListItemIcon>
            Administrator
          </MenuItem>
          <MenuItem>
            <ListItemIcon>
              <Settings fontSize="small" />
            </ListItemIcon>
            Settings
          </MenuItem>
          <MenuItem onClick={handleLogout}>
            <ListItemIcon>
              <LogOut fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      </Box>
    </Box>
  );
};

export default DashboardShellHeader;
