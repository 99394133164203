import { Box, Grid, Typography } from '@mui/material';

import { FileBrowserHeaderContainer } from '@components/FileBrowser/StyledComponents';

export const FileBrowserTableHeader = () => (
  <Box sx={{ margin: '10px' }}>
    <FileBrowserHeaderContainer container>
      <Grid item xs={4}>
        <Typography variant="caption">Name</Typography>
      </Grid>
      <Grid item xs={4} display={{ xs: 'none', sm: 'block' }}>
        <Typography variant="caption">Created At</Typography>
      </Grid>
      <Grid item xs={4} display={{ xs: 'none', sm: 'block' }}>
        <Typography variant="caption">File Size</Typography>
      </Grid>
    </FileBrowserHeaderContainer>
  </Box>
);
