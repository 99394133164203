import { Suspense } from 'react';
import ProjectEditForm from './Forms/ProjectEditForm';
import { MainContainer } from '../MainDashboard/StyledComponents';
import { ProjectFormElement } from './StyledComponents';

const ProjectEdit = () => (
  <ProjectFormElement>
    <input type="hidden" value="default" />
    <ProjectEditForm />
  </ProjectFormElement>
);

const ProjectEditWithSuspense = () => (
  <MainContainer sx={{ padding: 1 }}>
    <Suspense fallback={<div>Loading...</div>}>
      <ProjectEdit />
    </Suspense>
  </MainContainer>
);
export default ProjectEditWithSuspense;
