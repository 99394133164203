import { useState } from 'react';

import { useAtom } from 'jotai';
import { Badge, Drawer, IconButton } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import UploadsSidebar from '@components/DashboardShell/Uploads';
import { uploadsAtom } from '@/atoms/uploadsAtom';

const UploadsSidebarButton = () => {
  const [uploads] = useAtom(uploadsAtom);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const uploadCount = uploads.length;

  return (
    <div>
      <Badge badgeContent={uploadCount} color="primary">
        <IconButton onClick={() => setDrawerOpen(true)}>
          <CloudUploadIcon />
        </IconButton>
      </Badge>
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        ModalProps={{
          keepMounted: false,
        }}
      >
        <UploadsSidebar />
      </Drawer>
    </div>
  );
};

export default UploadsSidebarButton;
