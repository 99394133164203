import { ChangeEvent, useCallback, useState, useRef } from 'react';

import { Stack, Typography } from '@mui/material';

import CreateDirectoryDialog from '@components/FileBrowser/Components/Header/CreateDirectoryDialog';
import FileBrowserBreadcrumbs from '@components/FileBrowser/Components/Header/FileBrowserBreadcrumbs';
import { useAddFilesToUpload } from '@components/FileBrowser/hooks';
import AddNewMenu from '@components/FileBrowser/Components/Header/AddNewMenu';
import { useCreateDirectory } from '@api/fileHandling/fileHandling';

interface IFileBrowserHeaderProps {
  location: string;
}

export const FileBrowserHeader = ({ location }: IFileBrowserHeaderProps) => {
  const [createNewDialogOpen, setCreateNewDialogOpen] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const createDirectory = useCreateDirectory();
  const addFilesToUpload = useAddFilesToUpload();

  const onAddNewMenuChange = (context: 'file' | 'directory') => {
    if (context === 'directory') {
      setCreateNewDialogOpen(true);
      return;
    }

    if (context === 'file') {
      inputRef.current?.click();
    }
  };

  const onFilesSelected = useCallback(
    (e: ChangeEvent<HTMLInputElement>) =>
      addFilesToUpload(e.currentTarget.files || [], location),
    [addFilesToUpload, location],
  );

  const handleCreate = useCallback(
    (directoryName: string) => {
      createDirectory.mutate({ dirPath: `${location}${directoryName}` });
    },
    [createDirectory, location],
  );

  return (
    <Stack direction="column" sx={{ paddingX: 1 }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ paddingBottom: 2 }}
      >
        <input
          type="file"
          id="file"
          hidden
          ref={inputRef}
          onChange={onFilesSelected}
        />
        {createNewDialogOpen && (
          <CreateDirectoryDialog
            handleCreate={handleCreate}
            handleClose={() => setCreateNewDialogOpen(false)}
            location={location}
          />
        )}
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography variant="h5">File Browser</Typography>
          <AddNewMenu onChange={onAddNewMenuChange} />
          <FileBrowserBreadcrumbs location={location} />
        </Stack>
      </Stack>
    </Stack>
  );
};
