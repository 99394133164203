import { styled } from '@mui/material';

export const PatientFormElement = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: theme.spacing(3),
  flexGrow: 1,
  padding: 3,
  background: theme.palette.background.dashboardMainContent,
  minHeight: '100vh',
  zIndex: 49,
}));

export const HeaderElement = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: theme.spacing(3),
  padding: theme.spacing(1),
}));
