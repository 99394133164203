import { Search } from '@mui/icons-material';
import {
  Button,
  Grid,
  Stack,
  TextField,
  InputAdornment,
  Paper,
  styled,
} from '@mui/material';
import { GeneralStyledForm } from '@/components/GeneralForm/GeneralStyledForm';
import { SearchFieldsType } from '@/api/sample/types';
import { MainContainer } from '../../MainDashboard/StyledComponents';

const StyledSearchField = styled(TextField)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[1],
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.default,
  boxShadow: theme.shadows[3],
  border: `1px solid ${theme.palette.divider}`,
}));

interface SampleSearchProps {
  searchFields: SearchFieldsType;
  setSearchFields: React.Dispatch<React.SetStateAction<SearchFieldsType>>;
  handleSearch: () => void;
  clearSearch: () => void;
}

const SampleSearch = ({
  searchFields,
  setSearchFields,
  handleSearch,
  clearSearch,
}: SampleSearchProps) => {
  // Update search fields on input change
  const handleInputChange = (field: string, value: string) => {
    setSearchFields((prevFields) => ({
      ...prevFields,
      [field]: value,
    }));
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSearch();
    }
  };

  return (
    <MainContainer>
      <GeneralStyledForm>
        <StyledPaper>
          <Stack direction="column" spacing={3}>
            {/* Outer Grid to Center Content */}
            <Grid container justifyContent="center">
              {/* Inner Grid for the Search Fields */}
              <Grid item xs={12} sm={10} md={8}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} md={12}>
                    <StyledSearchField
                      id="search-1"
                      variant="outlined"
                      placeholder="Search by ID"
                      size="small"
                      value={searchFields.id}
                      onKeyDown={handleKeyDown}
                      onChange={(e) => handleInputChange('id', e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Search />
                          </InputAdornment>
                        ),
                      }}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/* Button Actions */}
            <Stack direction="row" spacing={2} justifyContent="center">
              <Button
                onClick={handleSearch}
                size="small"
                variant="contained"
                color="primary"
                fullWidth
                sx={{ maxWidth: '150px' }}
              >
                Search
              </Button>
              <Button
                onClick={clearSearch}
                size="small"
                variant="outlined"
                color="secondary"
                fullWidth
                sx={{ maxWidth: '150px' }}
              >
                Clear
              </Button>
            </Stack>
          </Stack>
        </StyledPaper>
      </GeneralStyledForm>
    </MainContainer>
  );
};

export default SampleSearch;
