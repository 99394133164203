import { useAtom } from 'jotai';
import { Paper, Typography } from '@mui/material';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import UploadCard from '@components/DashboardShell/Uploads/UploadCard';
import {
  UploadCardsContainer,
  UploadSidebar,
} from '@components/DashboardShell/Uploads/StyledComponents';
import { uploadsAtom } from '@/atoms/uploadsAtom';
import { MainContainer } from '@/pages/common/MainDashboard/StyledComponents';

const UploadsSidebar = () => {
  const [uploads] = useAtom(uploadsAtom);
  const uploadCount = uploads.length;

  return (
    <MainContainer sx={{ marginTop: '5rem' }}>
      <UploadSidebar>
        <Paper
          sx={{
            textAlign: 'center',
            bgcolor: 'primary.main',
            color: 'primary.contrastText',
          }}
          square
        >
          <Typography variant="h6">
            <CloudUploadOutlinedIcon fontSize="large" sx={{ mb: -1 }} /> Uploads
          </Typography>
          <Typography variant="subtitle2">{`Total: ${uploadCount}`}</Typography>
        </Paper>
        {uploadCount === 0 && (
          <Typography variant="subtitle1">No uploads</Typography>
        )}
        <UploadCardsContainer>
          {uploads.map((upload) => (
            <UploadCard
              key={upload.id}
              id={upload.id}
              location={upload.location}
            />
          ))}
        </UploadCardsContainer>
      </UploadSidebar>
    </MainContainer>
  );
};

export default UploadsSidebar;
