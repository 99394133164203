import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Stack } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { enqueueSnackbar } from 'notistack';
import { Warning } from '@mui/icons-material';
import { useGetPatient } from '@/api/patient';
import { PatientSchema } from '@/zod_schemas';
import { useDeletePatient } from '@/api/patient/patient';
import CustomDialog from '@/components/Dialog/CustomDialog';

const PatientList = () => {
  const { data, refetch } = useGetPatient();
  const [patientData, setPatientData] = useState<PatientSchema[]>([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const deletePatient = useDeletePatient();
  const [deleteId, setDeleteId] = useState<string | undefined>(undefined);
  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const onSubmitDialog = async () => {
    if (deleteId === undefined) {
      enqueueSnackbar('Failed to delete project', {
        variant: 'error',
      });
    } else {
      try {
        await deletePatient
          .mutateAsync(deleteId)
          .then(() => {
            enqueueSnackbar('Project delete successfully', {
              variant: 'success',
            });

            refetch();
          })
          .catch(() => {
            enqueueSnackbar('Failed to delete project', {
              variant: 'error',
            });
          });
      } catch (ex) {
        enqueueSnackbar('Error. Please contact admin', {
          variant: 'error',
        });
      }
    }
    handleDialogClose();
  };

  const navigate = useNavigate();

  useEffect(() => {
    // eslint-disable-next-line max-len
    // Normalize data to array. This API might return set of patient(array) or single patient(object)
    if (data) {
      const normalizedData = Array.isArray(data) ? data : [data];
      setPatientData(normalizedData);
    }
  }, [data]);

  const columns: GridColDef<PatientSchema>[] = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
    },
    {
      field: 'identification',
      headerName: 'Identification',
      flex: 1,
    },
    {
      field: 'ethnicity',
      headerName: 'Ethnicity',
      flex: 1,
    },
    {
      field: 'date_of_birth',
      headerName: 'Date of Birth',
      flex: 1,
    },
    {
      field: 'gender',
      headerName: 'Gender',
      flex: 1,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      flex: 0.8,
      renderCell: (params) => (
        <Box>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => {
              const { id } = params.row;
              navigate(`/patients/${id}`);
            }}
          >
            Edit
          </Button>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={() => {
              const { id } = params.row;
              setDialogOpen(true);
              setDeleteId(id);
            }}
            style={{ marginLeft: 8 }}
          >
            Delete
          </Button>

          <CustomDialog
            open={dialogOpen}
            onClose={handleDialogClose}
            title={
              <Stack direction="row" alignItems="center" spacing={1}>
                <Warning color="warning" />
                <span>Delete Patient</span>
              </Stack>
            }
            onSubmit={onSubmitDialog}
          >
            <div>Are you sure you want to delete patient?</div>
          </CustomDialog>
        </Box>
      ),
    },
  ];

  return (
    <Box sx={{ flexGrow: 1, height: '100%' }}>
      <DataGrid
        rows={patientData}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5]}
        checkboxSelection
        disableRowSelectionOnClick
      />
    </Box>
  );
};

export default PatientList;
