import { Suspense, useState } from 'react';
import { Button, Menu, MenuItem, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ProjectsList from '@pages/common/Project/Components/ProjectsList';
import { MainContainer } from '../MainDashboard/StyledComponents';

const ProjectsHeader = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleCreateProject = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const navigate = useNavigate();

  return (
    <MainContainer>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ paddingBottom: 2 }}
      >
        <Typography variant="h5">Projects</Typography>
        <Button
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleCreateProject}
          variant="contained"
          size="small"
        >
          Create Project
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem onClick={() => navigate('create')}>Default</MenuItem>
          <MenuItem onClick={() => navigate('/batch')}>Batch</MenuItem>
        </Menu>
      </Stack>
    </MainContainer>
  );
};

const Projects = () => (
  <Stack direction="column" sx={{ paddingX: 1 }}>
    <ProjectsHeader />
    <Suspense fallback={<div>Loading...</div>}>
      <ProjectsList />
    </Suspense>
  </Stack>
);

export default Projects;
