import { Grid, Typography } from '@mui/material';

import {
  DashboardButton,
  DisabledButton,
  DashboardContainer,
  Item,
  ImageContainer,
  Disclaimer,
} from '@pages/common/MainDashboard/StyledComponents';
import { useAtom } from 'jotai';
import logo from '@assets/logo.svg';
import analysis from '@assets/web-analysis-chart-svgrepo-com.svg';
import patient from '@assets/medical-records-svgrepo-com.svg';
import project from '@assets/dna-svgrepo-com.svg';
import { useNavigate } from 'react-router-dom';
import { isDrawerExpandedAtom } from '@/components/DashboardShell/hook';

const MainDashboard = () => {
  const navigate = useNavigate();
  const [isExpanded] = useAtom(isDrawerExpandedAtom); // Use Jotai atom

  return (
    <DashboardContainer>
      <Grid container spacing={6} justifyContent="center">
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item onClick={() => navigate('projects')}>
            <ImageContainer>
              <img src={project} alt="project" />
            </ImageContainer>
            <DashboardButton>
              <h2>Projects</h2>
            </DashboardButton>
          </Item>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item onClick={() => navigate('patients')}>
            <ImageContainer>
              <img src={patient} alt="patient" />
            </ImageContainer>
            <DashboardButton>
              <h2>Patients</h2>
            </DashboardButton>
          </Item>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item onClick={() => navigate('patients')}>
            <ImageContainer>
              {/* <img src={patient} alt="patient" /> */}
            </ImageContainer>
            <DashboardButton>
              <h2>Samples</h2>
            </DashboardButton>
          </Item>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item>
            <ImageContainer>
              <img src={analysis} alt="analysis" />
            </ImageContainer>
            <DashboardButton>
              <h2>Analysis</h2>
            </DashboardButton>
          </Item>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item
            sx={{
              padding: '0px',
            }}
          >
            <DisabledButton>
              <h2>Pipelines</h2>
            </DisabledButton>
          </Item>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item
            sx={{
              padding: '0px',
            }}
          >
            <DisabledButton>
              <h2>TBA</h2>
            </DisabledButton>
          </Item>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Item
            sx={{
              padding: '0px',
            }}
          >
            <DisabledButton>
              <h2>TBA</h2>
            </DisabledButton>
          </Item>
        </Grid>
      </Grid>

      <Disclaimer isExpanded={isExpanded}>
        <Grid
          container
          spacing={2}
          justifyContent="center"
          sx={{
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          <Grid
            item
            xs={12}
            sm={3}
            container
            sx={{
              width: '100%',
            }}
          >
            <Grid container justifyContent="center" alignItems="center">
              <ImageContainer
                sx={{
                  width: '0.2rem',
                  padding: '2rem',
                }}
              >
                <img src={logo} alt="logo" />
              </ImageContainer>
              <Typography
                sx={{
                  fontSize: '0.8rem',
                  textAlign: 'center',
                }}
              >
                &copy; 2023 Synapse
                <br />
                All Rights Reserved
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={9} container alignItems="center">
            Disclaimer: Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            Nulla molestie tempor elementum. Donec nec dapibus erat. Sed rutrum
            bibendum arcu non bibendum. Integer quis luctus risus, et viverra
            justo. Proin non purus in mauris porttitor aliquam. Morbi hendrerit
            faucibus nunc tempus euismod. Curabitur pulvinar elit nec elit
            rhoncus vulputate. Lorem ipsum dolor sit amet, consectetur
            adipiscing elit. Nulla molestie tempor elementum. Donec nec dapibus
            erat. Sedrutrum bibendum arcu non bibendum. Integer quis luctus
            risus, et viverra justo.
          </Grid>
        </Grid>
      </Disclaimer>
    </DashboardContainer>
  );
};

export default MainDashboard;
