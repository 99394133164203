import { ReactNode, useMemo } from 'react';
import {
  Box,
  CssBaseline,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  AppBar,
  IconButton,
  Divider,
  Theme,
} from '@mui/material';
import { useAtom } from 'jotai';
import { useNavigate } from 'react-router-dom';
import { BadgeHelp } from 'lucide-react';
import logo from '@assets/logo.svg';
import MenuIcon from '@mui/icons-material/Menu';
import {
  Drawer,
  MainContentContainer,
  SectionListContainer,
  Toolbar,
} from './StyledComponents';
import dashboardSections from './dashboardSections';
import DashboardShellHeader from './Components/DashboardShellHeader';
import { isDrawerExpandedAtom } from './hook';

interface IDashboardShellProps {
  children: ReactNode;
}

const drawerWidthExpanded = 240;
const drawerWidthCollapsed = 62;

const DashboardShell = ({ children }: IDashboardShellProps) => {
  const navigate = useNavigate();
  const [isExpanded, setIsExpanded] = useAtom(isDrawerExpandedAtom);

  const toggleDrawer = () => {
    setIsExpanded((prev) => !prev);
  };

  const drawerStyle = useMemo(
    () => ({
      width: isExpanded ? drawerWidthExpanded : drawerWidthCollapsed,
      flexShrink: 0,
      transition: 'width 0.3s ease', // Smooth transition for Drawer
      '& .MuiDrawer-paper': {
        width: isExpanded ? drawerWidthExpanded : drawerWidthCollapsed,
        boxSizing: 'border-box',
        transition: (theme: Theme) =>
          theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
      },
    }),
    [isExpanded],
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          background: 'linear-gradient(90deg, #0072E5 0%, #00C6FF  100%)', // Gradient color
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Subtle shadow
        }}
      >
        <Toolbar
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={toggleDrawer}
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
            {isExpanded && (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <img src={logo} alt="logo" style={{ height: 40 }} />
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: 600,
                    letterSpacing: '0.5px',
                    color: '#FFFFFF', // White text for contrast
                  }}
                >
                  SYNAPSYS
                </Typography>
              </Box>
            )}
          </Box>
          <Box>
            {/* Add action buttons or icons here, like user profile or notifications */}
            <Box>
              <DashboardShellHeader />
            </Box>
          </Box>
        </Toolbar>
      </AppBar>

      <Drawer variant="permanent" open={isExpanded} sx={drawerStyle}>
        <Toolbar />
        <List sx={{ height: '100%' }}>
          <SectionListContainer>
            <div>
              {dashboardSections.map(({ name, path, id, Icon }) => (
                <ListItem key={id} disablePadding sx={{ display: 'block' }}>
                  <ListItemButton
                    sx={{
                      minHeight: 70,
                      justifyContent: isExpanded ? 'initial' : 'center',
                    }}
                    onClick={() => navigate(path)}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: isExpanded ? 3 : 'auto',
                        justifyContent: 'center',
                      }}
                    >
                      <Icon />
                    </ListItemIcon>
                    {isExpanded && <ListItemText primary={name} />}
                  </ListItemButton>
                </ListItem>
              ))}
            </div>
            <div>
              <Divider />
              <ListItem key={99} disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                  sx={{
                    minHeight: 70,
                    justifyContent: isExpanded ? 'initial' : 'center',
                  }}
                  onClick={() => navigate('help')}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: isExpanded ? 3 : 'auto',
                      justifyContent: 'center',
                    }}
                  >
                    <BadgeHelp />
                  </ListItemIcon>
                  {isExpanded && <ListItemText primary="Help" />}
                </ListItemButton>
              </ListItem>
            </div>
          </SectionListContainer>
        </List>
      </Drawer>
      <MainContentContainer>{children}</MainContentContainer>
    </Box>
  );
};

export default DashboardShell;
